.shaking__item {
  -webkit-animation: shaking 5s infinite;
  animation: shaking 5s infinite;
}

@-webkit-keyframes shaking {
  0% {
    transform: rotate(4deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  15% {
    transform: rotate(7deg);
  }
  20% {
    transform: rotate(-6deg);
  }
  30% {
    transform: rotate(6deg);
  }
  40% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(7deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes shaking {
  0% {
    transform: rotate(4deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  15% {
    transform: rotate(7deg);
  }
  20% {
    transform: rotate(-6deg);
  }
  30% {
    transform: rotate(6deg);
  }
  40% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(7deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
